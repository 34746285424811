<template>
    <div class="mobile-visible">    
      <div class="h-1 w-full bg-neutral-200 dark:bg-neutral-600">
        <div ref="ProgressBar" class="h-1 bg-primary progress-bar"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  };
  </script>
  
  <style scoped>
    .progress-bar {
    width: 0;
    animation-name: progress;
    animation-duration: 1.3s;
    }

    @keyframes progress {
        from {width:0px}
        to {width:100%}
    }

  </style>